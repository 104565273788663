
.c-box {
  background-color: $white;
  border: 1px solid $bdrColor;
  padding: 33px 40px 30px;
  margin-bottom: 20px;
  @include mq-max(sm) {
    padding: 20px 15px 15px;
  }
  &:last-child {
    margin-bottom: 0
  }

  &--bgLightGray {
    background-color: $gray-l;
  }
}


.c-whiteBox {
  background-color: $white;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 60px 95px 75px;
    @include mq-max(content) {
      padding: 60px 45px 75px;
    }
    @include mq-max(sm) {
      padding: 40px 15px 55px;
    }
  }

}