// クラス付与型ホバーアクション
// クラスのありなしでホバーをカスタマイズできる

.u-hover {
  &--underline {
    &>span {
      display: inline-block;
      position: relative;
      &::after {
        content: '';
        width: 0%;
        height: 1px;
        display: block;
        background-color: $keyColor;
        position: absolute;
        left: 0;
        bottom: -5px;
        transition: all $transition !important;
      }
    }
    &:hover {
      &>span {
        &::after {
          width: 100%;
          @include mq-max($changePoint) {
            width: 0%;
          }
        }
      }
    }
  }
}