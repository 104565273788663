//画像系

.c-picture {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  img {
    display: block;
    line-height: 1;
  }
}