//テーブル
//テキストアラインはユーティリティクラスを使ってください。

.c-tbl {
  //共通設定
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  th {
    border-top: 1px solid $keyColor;
    border-bottom: 1px solid $keyColor;
  }
  td {
    border-top: 1px solid $bdrColor;
    border-bottom: 1px solid $bdrColor;
  }
  th,td {
    // border: 1px solid $bdrColor;
    padding: 19px 20px;
    text-align: left;
    @include mq-max(xs) {
      padding: 15px 10px;
    }
  }


  //テーブルタイプ
  //ブロック
  &--block {
    tbody {
      tr {
        &:not(:last-child) {
          td{
            @include mq-max(xs) {
              border-bottom: none;
            }
          }
        }
        &:last-child {
          td {
            @include mq-max(xs) {
              border-bottom-color: $keyColor;
            }
          }
        }
        th {
          width: 220px;
          @include mq-max(xs) {
            width: 100%;
            border-bottom: none !important;
            text-align: left;
          }
        }
        // td {}
        th,td {
          @include mq-max(xs) {
            display: block;
            width: 100%;
          }
        }
      }
    }

    //Modifier
    &--lg {
      th,td {
        padding-top: 33px;
        padding-bottom: 33px;
      }
    }

    &--half {
      th,td {
        width: 50%;
        @include mq-max(xs) {
          width: 100%;
        }
      }
    }
  }

  //Fixed
  &--fixed {
    table-layout: fixed;
    tbody {
      tr {
        th {
          &.c-tbl {
            //デフォルト横幅
            &__th {
              width: 220px;
              @include mq-max(sm) {
                width: 160px;
              }
            }
          }
        }
        td {
          &.c-tbl {
            //固定横幅
            &__td {
              width: 370px;
            }
          }
        }
        th,td {
          border-right: 1px dashed $bdrColor;
          @include mq-max(sm) {
            padding: 15px 10px;
            font-size: 14px;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    .c-tbl {
      &__item {
        //長さ指定のクラスをここに作成
        &--w-50per {
          width: 50%;
        }
        &--w-25per {
          width: 25%;
        }
        &--w-200px {
          width: 200px;
        }
      }
    }
  }

  //スクロール型
  &__scroll {
    @include mq-max(xs) {
      overflow: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid $bdrColor;
      border-radius: 5px;
      &::before {
        content: "←スライドで表の詳細を確認できます→";
        color: #666;
        font-size: 90%;
        display: block;
        margin-bottom: 10px;
        text-align: center;
      }
      table {
        min-width: 800px; //ここは状況に応じて値を変更してください
        th, td {
          display: table-cell;
        }
        th {
          white-space: nowrap;
        }
      }

      .eng & {
        &::before {
          content: '← You can see the details of the table on the slide. →';
        }
      }
    }
  }

  //スクロール型（常時）
  &__scrollAll {
    overflow: hidden;
    overflow-x: scroll;
    padding: 15px;
    border: 1px solid $bdrColor;
    border-radius: 5px;
    &::before {
      content: "←スライドで表の詳細を確認できます→";
      color: #666;
      font-size: 90%;
      display: block;
      margin-bottom: 10px;
      text-align: left;
    }
    table {
      min-width: 800px; //ここは状況に応じて値を変更してください
      th, td {
        display: table-cell;
      }
      th {
        white-space: nowrap;
      }
    }

    .eng & {
      &::before {
        content: '← You can see the details of the table on the slide. →';
      }
    }
  }


  //内臓パーツ
  &__td {
    &--pr0 {
      padding-right: 0 !important;
      @include mq-max(md) {
        padding-right: 20px !important;
      }
      @include mq-max(xs) {
        padding-right: 10px !important;
      }
    }
  }
  &__inner {
    margin: 0 -10px -20px;
    &-left,&-right {
      padding: 0 10px 20px;
      width: 100%;
    }
    &-left {
      &--w70 {
        width: 70%;
        @include mq-max(md) {
          width: 100%;
        }
      }
    }
    &-right {
      &--w30 {
        width: 30%;
        @include mq-max(md) {
          width: 100%;
        }
      }
    }

    &--between {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mq-max(md) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    // &-zipcode {}
    // &-address {}
  }
}