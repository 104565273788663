//ページ内アンカーリンク

.c-anchor {
  margin: 0 -10px -20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include mq-max(xs) {
    justify-content: flex-start;
    margin: 0 -5px -10px;
  }

  &__item {
    padding: 0 10px 20px;
    @include mq-max(xs) {
      padding: 0 5px 10px;
      width: 50%;
    }
    .c-btn {
      @include mq-max(xs) {
        width: 100%;
      }

      //ハイライト
      &.is-current {
        background-color: $keyColor;
        color: $white;
      }
    }
  }


  //レイアウト
  &__section {
    padding-top: 0;
    padding-bottom: 0;
    @include mq-max(content) {
      padding-bottom: 80px;
    }

    //アンカーリンク上部固定
    &--sticky {
      background-color: $white;
      position: sticky;
      top: $headerHeight;
      z-index: 2;
      padding-bottom: 0;
      margin-bottom: 0;
      @include mq-max(content) {
        margin-bottom: 60px;
        top: $headerHeight-sp;
      }
    }
    //スマホ時に一列の横スクロール
    &--spScroll {
      .c-anchor {
        @include mq-max($changePoint) {
          flex-wrap: nowrap;
          justify-content: flex-start;
          margin: 0 -10px;
        }
        &__container {
          @include mq-max($changePoint) {
            overflow-x: scroll;
          }
        }
        &__item {
          @include mq-max($changePoint) {
            min-width: 170px;
            padding: 0 10px;
          }
          @include mq-max(xs) {
            min-width: max-content;
            padding: 0 5px;
          }
          &--max-content {
            @include mq-max($changePoint) {
              min-width: max-content;
            }
          }
        }
      }
    }
  }

  &__container {
    width: 100%;
    padding: 15px $contentPadding;
    border-bottom: 1px solid $bdrColor;
    transition: all $transition;
    @include mq-max(sm) {
      padding: 15px $contentPadding/2;
    }
    // &--small {
    //   padding: 15px $contentPadding;
    // }
  }

  &__wrap {
    max-width: $contentWidth;
    margin: 0 auto;
    &--mw800 {
      max-width: 800px;
    }
  }
}