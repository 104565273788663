//テキスト系のユーティリティクラス

.u-txt {
  //半分ボーダー
  &-bdr {
    padding: 0 5px;
    &--keyColor {
      background: linear-gradient(transparent 60%, $keyColor 60%);
    }
    &--yellow {
      background: linear-gradient(transparent 60%, #FFEF69 60%);
    }
  }

  &-color {
    &--keyColor {
      color: $keyColor;
    }
    &--red {
      color: $red;
    }
    &--white {
      color: $white;
    }
  }

  &-fs {
    &--18 {
      font-size: 18px;
    }
  }

  &-weight {
    &--bold {
      font-weight: 600;
    }
  }
}


//英語フォント
.u-font {
  &--en {
    font-family: $serif-en;
  }
}