//CVエリア
.c-cvArea {
  background-color: $keyColor;
  max-width: $contentWidth;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  @include mq-max(content) {
    max-width: calc(100% - #{$contentPadding});
  }
  &__inner {
    padding: 60px 85px 70px;
    @include mq-max(content) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include mq-max(md) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include mq-max(bp600) {
      padding: 30px 15px 40px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-max(bp600) {
      flex-direction: column;
    }
  }

  &__head {
    // border-right: 1px solid $white;
    padding-right: 25px;
    margin-right: 40px;
    flex-shrink: 0;
    @include mq-max(md) {
      padding-right: 15px;
      margin-right: 25px;
    }
    @include mq-max(bp600) {
      margin-right: 0;
      padding-right: 0;
      margin-bottom: 20px;
      padding-bottom: 20px;
      // border-right: none;
      border-bottom: 1px solid $white;
      // flex: 1 1 100%;
      width: 100%;
    }
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    color: $white;
    margin-bottom: 8px;
    @include mq-max(md) {
      font-size: 30px;
    }
    @include mq-max(bp600) {
      text-align: center;
    }
    &>small {
      font-size: 16px;
      font-weight: 600;
      display: block;
      margin-bottom: -3px;
    }
  }
  &__txt {
    color: $white;
    line-height: 1.5;
    margin-bottom: 0;
    @include mq-max(md) {
      font-size: 14px;
    }
    @include mq-max(bp600) {
      text-align: center;
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    @include mq-max(content) {
      justify-content: space-between;
    }
    @include mq-max(iPadPro-v) {
      flex-direction: column;
    }
    @include mq-max(bp600) {
      width: 100%;
    }
  }

  &__btn {
    flex-grow: 1;
    display: block;
    background-color: $white;
    color: $keyColor;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 25.5px 15px;
    max-width: 700px;
    width: 100%;
    border: 1px solid $keyColor;
    transition: all $transition;
    @include mq-max(md) {
      font-size: 15px;
    }
    @include mq-max(bp600) {
      max-width: 375px;
    }
    &:hover {
      color: $white !important;
      background-color: $keyColor;
      border-color: $white;
    }
    &>i {
      margin-right: 3px;
    }
  }
}