.l-header {
  background-color: $white;
  box-shadow: $headerBoxShadow;
  transition: all $transition;
  @include mq-max($changePoint) {
    box-shadow: none;
  }
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: $headerHeight;
    margin: 0 auto;
    padding: 0;
    position: relative;
    z-index: 100;
    @include mq-max($changePoint) {
      display: block;
      height: $headerHeight-sp;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 20px;
    line-height: 0;
    display: inline-block;
    width: 165px; // 状況によって変更可
    @include mq-max($changePoint) {
      margin: 0;
      width: 120px;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: $white;
    padding-left: 20px;
    @include mq-max($changePoint) {
      height: 100%;
      padding: 10px;
      background-color: $white;
      line-height: 0;
      display: flex;
      align-items: center;
      box-shadow: $headerBoxShadow;
    }
  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max($changePoint) {
    display: block;
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;
    span {
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 3px $keyColor;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1) {
        top: 9px;
      }
      &:nth-child(2) {
        top: 18px;
      }
      &:nth-child(3) {
        top: 27px;
      }
    }
    &.is-toggleActive {
      span{
        &:nth-child(1) {
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3) {
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }

    &::after {
      content: 'メニュー';
      font-size: 10px;
      font-family: $serif;
      font-weight: 600;
      color: $keyColor;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }

    html.eng & {
      &::after {
        content: 'MENU';
      }
    }
  }
}


//headerを透明に
.is-bgNone {
  margin-top: 0;
  @include mq-max($changePoint) {
    margin-top: $headerHeight-sp;
  }

  &--active {
    .l-header {
      background-color: transparent;
      box-shadow: none;
      @include mq-max($changePoint) {
        background-color: $white;
      }
    }

    .l-navigation {
      &__item {
        &>a {
          color: $white;
          @include mq-max($changePoint) {
            color: $keyColor;
          }
        }
      }

      &__lang {
        color: $white;
        @include mq-max($changePoint) {
          color: $keyColor;
        }
        &::before {
          color: $white;
          @include mq-max($changePoint) {
            color: $keyColor;
          }
        }
        &-item {
          &::after {
            color: $white;
            @include mq-max($changePoint) {
              color: $keyColor;
            }
          }
          &>a {
            color: $white;
            @include mq-max($changePoint) {
              color: $keyColor;
            }
            &:hover {
              color: $keyColor !important;
            }
            &.current {
              color: $white;
              @include mq-max($changePoint) {
                color: $keyColor;
              }
            }
          }
        }
      }

      &__contact {
        &>a {
          background-color: transparent;
          position: relative;
          @include mq-max($changePoint) {
            background-color: $keyColor;
          }
          &::after {
            content: '';
            width: 1px;
            height: 46px;
            background-color: $white;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include mq-max($changePoint) {
              content: none;
            }
          }
        }
      }
    }

    &#top {
      .p-mv {
        &__video {
          height: 100vh;
          @include mq-max($changePoint) {
            height: calc(100vh - #{$headerHeight-sp});
          }
        }
      }
    }
  }
}