//オリジナルmixin

//大き目の数字
@mixin indexNumber {
  font-size: 85px;
  font-weight: 600;
  color: $keyColor;
  line-height: 1;
  @include mq-max(md) {
    font-size: 60px;
  }
  @include mq-max(xs) {
    font-size: 36px;
  }
}

//青と赤の四角
@mixin blue-red_block {
  display: inline-block;
  width: 20px;
  height: 10px;
  background: linear-gradient(to right, $keyColor 50%, $accentColor 50%);
}