//リンク

.c-link {
  font-size: 16px;
  font-weight: 600;
  color: $keyColor;
  position: relative;
  display: inline-block;
  &::before {
    @include fa('f022');
    margin-right: 10px;
  }
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    background-color: $keyColor;
    bottom: 0;
    left: 0;
  }
}