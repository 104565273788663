///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 20px 45px 20px 26px;
  max-width: 100%;
  width: auto;
  color: $keyColor;
  background-color: $white;
  line-height: 1.25;
  border: 1px solid $keyColor;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  transition: all $transition;
  box-shadow: $box-shadow;
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    @include fa('f054');
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: right $transition;
  }
  &:hover {
    color: $white;
    background-color: $keyColor;
    box-shadow: $box-shadow-hover;
    &::after {
      right: 10px;
    }
  }


  //PDF
  &[href$=".pdf"] {
    &:hover {
      &::after {
        right: 16px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &::after {
      color: $keyColor;
    }
    &:hover {
      &::after {
        right: 16px;
        color: $white;
      }
    }
  }


  //Modifier
  //最小幅指定
  &--minWidth {
    min-width: 236px;
    @include mq-max(xs) {
      min-width: auto;
      width: 100%;
    }
  }

  //横幅100%
  &--fullWidth {
    width: 100%;
  }

  //大きいボタン
  &--large {
    font-size: 20px;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  //小さいボタン
  &--small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //アロー下向き
  &--arrowBottom {
    &::after {
      @include fa('f107');
      font-size: 18px;
    }
    &:hover {
      &::after {
        right: 16px;
      }
    }
  }


  //アイコン付き
  &--ico {
    font-size: 18px;
    padding: 26px 15px;
    display: block;
    @include mq-max(sm) {
      font-size: 16px;
      padding: 20px 15px;
    }
    &::before {
      content: '';
      display: block;
      margin: 0 auto 4px;
      height: 34px;
    }
    &::after {
      content: none;
    }
    &:hover {
      background-color: $white;
      color: $keyColor;
      &>span {
        &::after {
          right: -5px;
        }
      }
    }

    //各アイコン
    &--green {
      &::before {
        width: 33px;
        background: url('/inc/image/common/ico/ico_green.svg')no-repeat center / contain;
      }
    }
    &--global {
      &::before {
        width: 33px;
        background: url('/inc/image/common/ico/ico_global.svg')no-repeat center / contain;
      }
    }
    &--safety {
      &::before {
        width: 40px;
        background: url('/inc/image/common/ico/ico_safety.svg')no-repeat center / contain;
      }
    }
    &--faq {
      &::before {
        width: 22px;
        background: url('/inc/image/common/ico/ico_question.svg')no-repeat center / contain;
      }
    }
    &--flow {
      &::before {
        width: 27px;
        background: url('/inc/image/common/ico/ico_schedule.svg')no-repeat center / contain;
      }
    }
    &--shinsotsu {
      &::before {
        width: 34px;
        background: url('/inc/image/common/ico/ico_shinsotsu.svg')no-repeat center / contain;
      }
    }
    &--career {
      &::before {
        width: 34px;
        background: url('/inc/image/common/ico/ico_career.svg')no-repeat center / contain;
      }
    }

    &>span {
      display: inline-block;
      position: relative;
      padding-right: 13px;
      &::after {
        @include fa('f105');
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: all $transition;
      }
    }
  }

  //下に色付き線を付ける
  //色設定
  $bdr-red: #e72b3d;
  $bdr-pink: #e04c94;
  $bdr-aqua: #2b9cbd;
  $bdr-blue: #305aba;
  $bdr-green: #338756;
  $bdr-yellow: #f5dc20;
  $bdr-gold: #c1a359;
  $bdr-orange: #f09339;
  $bdr-purple: #8a65a8;

  &--bdrBottom {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      display: block;
      width: calc(100% + 2px);
      height: 6px;
      position: absolute;
      bottom: -1px;
      left: -1px;
      pointer-events: none;
    }
    &--red {
      &::before {
        background-color: $bdr-red;
      }
    }
    &--purple {
      &::before {
        background-color: $bdr-pink;
      }
    }
    &--aqua {
      &::before {
        background-color: $bdr-aqua;
      }
    }
    &--blue {
      &::before {
        background-color: $bdr-blue;
      }
    }
    &--green {
      &::before {
        background-color: $bdr-green;
      }
    }
    &--orange {
      &::before {
        background-color: $bdr-orange;
      }
    }
    &--blue-yellow {
      &::before {
        background: linear-gradient(to left,$bdr-yellow 50% , $bdr-blue 50%);
      }
    }
    &--green-gold {
      &::before {
        background: linear-gradient(to left,$bdr-gold 50% , $bdr-green 50%);
      }
    }
    &--purple-orange {
      &::before {
        background: linear-gradient(to left,$bdr-purple 50% , $bdr-orange 50%);
      }
    }
  }
}


//サムネイル付きボタン
.c-picBtn {
  display: flex;
  align-items: center;
  border: 1px solid $keyColor;
  background-color: $white;
  transition: all $transition;
  box-shadow: $box-shadow;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &__thumb {
    width: 140px;
    height: 100%;
    flex-shrink: 0;
    overflow: hidden;
    @include mq-max(iPadPro-v) {
      width: 100px;
    }
    img {
      display: block;
      line-height: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;";
    }
  }
  &__body {
    display: block;
    flex-grow: 1;
    transition: all $transition;
    padding: 10px 40px 10px 40px;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    @include mq-max(iPadPro-v) {
      padding-left: 20px;
      font-size: 18px;
    }
    &::after {
      @include fa('f054');
      font-size: 10px;
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
      transition: right $transition;
    }
    .eng & {
      @include mq-max(iPadPro-v) {
        font-size: 16px;
      }
    }
  }
  &:hover {
    box-shadow: $box-shadow-hover;

    .c-picBtn {
      &__body {
        &::after {
          right: 12px;
        }
      }
    }
  }
}