
//位置調整クラス
.l-cvAreaPos {
  padding-top: 120px;
  position: relative;
  @include mq-max(bp600) {
    padding-top: 200px;
  }
  .c-cvArea {
    width: 100%;
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.l-f {
  background-color: $white;
  border-top: 1px solid $bdrColor;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    overflow: hidden;
    @include mq-max(content) {
      padding: 0 $contentPadding+10px;
    }
    @include mq-max(sm) {
      padding: 0 $contentPadding;
    }
    @include mq-max(xs) {
      padding: 0 $contentPadding/2;
    }
  }

  //上部
  &__head {
    width: 100%;

    .l-f {
      &__inner {
        padding-top: 65px;
        padding-bottom: 75px;
        @include mq-max(bp900) {
          flex-direction: column;
          padding-bottom: 40px;
        }
        @include mq-max(xs) {
          padding-bottom: 20px;
        }
      }
    }
  }
  //会社情報
  &__info {
    flex: 0 0 375px;
    @include mq-max(md) {
      flex: 0 0 250px;
    }
    @include mq-max(bp900) {
      flex: auto;
      margin-bottom: 30px;
    }
    &-logo {
      max-width: 165px;
      width: 100%;
      margin-bottom: 20px;
      @include mq-max(bp900) {
        margin: 0 auto 20px;
      }
      &>a {
        display: block;
        line-height: 1;
      }
      img {
        width: 100%;
        display: block;
        line-height: 1;
      }
    }
    &-list {
      font-size: 16px;
      @include mq-max(bp900) {
        font-size: 14px;
        line-height: 2;
      }
    }
    &-item {
      @include mq-max(md) {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      }
      @include mq-max(bp900) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
      }
      &:last-child {
        @include mq-max(md) {
          margin-bottom: 0;
        }
      }
      &>span {
        margin-right: .7em;
        @include mq-max(md) {
          margin-right: 0;
        }
        @include mq-max(bp900) {
          margin-right: .7em;
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  //リンク
  &__container {
    flex-grow: 1;

    //パーツ調整
    .l-navigation__lang {
      justify-content: flex-end;
      margin-top: 0;
      padding-right: 35px;
      background-color: transparent;
      @include mq-max(content) {
        padding-right: 0;
      }
      @include mq-max(bp600) {
        justify-content: center;
        padding: 10px 15px;
      }
    }
  }
  &__links {
    padding: 0 45px;
    @include mq-max(content) {
      padding: 0 25px;
    }
    @include mq-max(bp600) {
      padding: 0 15px;
      width: 50%;
      margin-bottom: 20px;
    }
    &-wrap {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      flex-wrap: wrap;
      padding-left: 30px;
      margin-left: -45px;
      margin-right: -45px;
      @include mq-max(content) {
        margin-left: -25px;
        margin-right: -25px;
      }
      @include mq-max(md) {
        padding-left: 0;
      }
      @include mq-max(bp900) {
        justify-content: center;
      }
      @include mq-max(bp600) {
        justify-content: flex-start;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 0;
      }
    }
  }
  &__link {
    // &-item {}
    &--parent {
      .l-f {
        &__link {
          &-item {
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            &>a {
              color: $textColor;
              font-size: 15px;
              font-weight: 600;
              @include mq-max(bp600) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    &--child {
      padding-top: 10px;
      padding-left: .8em;
      .l-f {
        &__link {
          &-item {
            margin-bottom: 8px;
            position: relative;
            &::after {
              content: '';
              display: block;
              width: 5px;
              height: 1px;
              background-color: $keyColor;
              position: absolute;
              top: 50%;
              left: -10px;
              transform: translateY(-50%);
            }
            &:last-child {
              margin-bottom: 0;
            }
            &>a {
              font-size: 14px;
              font-weight: 400;
              @include mq-max(bp600) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }


  //下部
  &__bottom {
    width: 100%;
    border-top: 1px solid $bdrColor;
    &-list {
      display: flex;
      align-items: center;
      @include mq-max(sm) {
        margin-bottom: 15px;
      }
    }
    &-item {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      &>a {
        font-size: 13px;
        font-weight: 400;
        color: $textColor;
      }
    }

    .l-f {
      &__inner {
        padding-top: 35px;
        padding-bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mq-max(sm) {
          flex-direction: column-reverse;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }


  //Copyright
  &__copyright {
    font-size: 14px;
    font-weight: 400;
  }
}
