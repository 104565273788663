///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

//中間お知らせ基本形
.c-newslist01 {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    border-bottom: 1px solid $bdrColor;
    &:first-child {
      border-top: 1px solid $bdrColor;
    }
    &>a {
      display: block;
      transition: all $transition;
      padding: 28px 40px 28px 35px;
      position: relative;
      @include mq-max(sm) {
        padding: 13px 30px 13px 15px;
      }
      &:after {
        @include fa('f105');
        color: $textColor;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: all $transition;
        @include mq-max(sm) {
          right: 10px;
        }
      }

      &[target="_blank"] {
        &:after {
          @include fa('f35d');
          -webkit-font-smoothing:antialiased;
        }
      }
      &[href$=".pdf"] {
        &:after {
          @include fa('f1c1');
          font-weight: 400;
          color: $red;
          -webkit-font-smoothing:antialiased;
        }
      }

      //ホバー時
      &:hover {
        dd {
          color: $keyColor;
        }
        &:after {
          color: $keyColor;
        }
        .c-newslist01 {
          &__date {
            color: $keyColor;
          }
          &__cat {
            background-color: $keyColor;
          }
        }
      }
    }

    dl {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    dt {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    dd {
      flex-grow: 1;
      font-size: 16px;
      font-weight: 600;
      color: $textColor;
      transition: all $transition;
      @include mq-max(sm) {
        font-size: 15px;
      }
    }
  }

  &__date {
    font-size: 16px;
    font-weight: 400;
    margin-right: 18px;
    color: $textColor;
    transition: all $transition;
    @include mq-max(sm) {
      font-size: 13px;
      margin-right: 10px;
    }
  }
  &__cat {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .04em;
    color: $white;
    background-color: $keyColor;
    display: block;
    padding: 2px 5px;
    min-width: 60px;
    text-align: center;
    transition: all $transition;
    @include mq-max(sm) {
      font-size: 13px;
      padding: 2px 5px;
      min-width: 67px;
    }

    .eng & {
      letter-spacing: normal;
    }
  }
}


//上部お知らせ基本形
.c-newslist02 {}