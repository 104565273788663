//カード

//カードリンク
.c-card {
  border: 1px solid $keyColor;
  box-shadow: $box-shadow;
  transition: all $transition;
  &__link {
    display: block;

    //ホバー
    &:hover {
      .c-card {
        box-shadow: $box-shadow-hover;
        &__more {
          &::after {
            right: -5px;
          }
        }
      }
    }
  }

  &__thumb {
    width: 100%;
    img {
      width: 100%;
      height: 210px;
      display: block;
      line-height: 1;
      object-fit: cover;
      font-family: "object-fit: cover;";
    }
  }
  &__body {
    background-color: $white;
    padding: 14px 25px 55px;
    position: relative;
    @include mq-max(content) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__name {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
    &--mb0 {
      margin-bottom: 0;
    }
  }
  &__txt {
    line-height: (28 / 16) !important;
    margin-bottom: 0;
    color: $textColor;
  }
  &__more {
    font-size: 13px;
    font-weight: 600;
    color: $keyColor;
    display: inline-block;
    line-height: 1;
    padding-right: 10px;
    position: absolute;
    bottom: 25px;
    left: 25px;
    &::after {
      @include fa('f105');
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      transition: all $transition;
    }

    &--wide {
      width: calc(100% - 25px * 2);
    }
  }
}


//説明カード
.c-contentCard {
  &__img {
    margin-bottom: 15px;
  }
  // &__body {}

  //レイアウト(gridlexと併用)
  &__wrap {
    margin: 0 -10px -40px;
    @include mq-max(sm) {
      margin-bottom: -50px;
    }
  }
  &__item {
    padding: 0 10px 40px;
    @include mq-max(sm) {
      padding-bottom: 50px;
    }
  }
}


//実績カード
.c-resultCard {
  border: 1px solid $bdrColor;

  &__inner {
    padding: 30px 15px;
    text-align: center;
    @include mq-max(xs) {
      padding: 20px 10px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    @include mq-max(xs) {
      font-size: 16px;
    }
  }
  &__sub {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.2;
    @include mq-max(xs) {
      font-size: 12px;
    }
    &--mbMinus {
      margin-bottom: -10px;
    }
    &--bold {
      font-weight: 700;
    }
  }
  &__number {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
    @include mq-max(xs) {
      font-size: 16px;
    }
    &>strong {
      font-size: 34px;
      font-weight: 700;
      color: $keyColor;
      padding: 0 6px;
      @include mq-max(xs) {
        font-size: 24px;
      }
    }
  }


  //レイアウト
  &__list {}
  &__item {}
}