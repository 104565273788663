///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {

  //メイン見出し
  &-main {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.3;
    color: $keyColor;
    margin-bottom: 10px;
    @include mq-max(sm) {
      font-size: 28px;
    }
    @include mq-max(xs) {
      font-size: 24px;
    }
    &>small {
      font-size: 16px;
      font-weight: 600;
      display: block;
      margin-bottom: 5px;
      @include mq-max(xs) {
        font-size: 14px;
      }
    }

    //アイコンあり
    &--icon {
      &>small {
        display: flex;
        align-items: flex-start;
        &::before {
          content: '';
          @include blue-red_block;
          margin-right: 10px;
          position: relative;
          top: .3em;
        }
      }
    }
    //アイコンのみ
    &--iconOnly {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      &::before {
        content: '';
        @include blue-red_block;
        position: relative;
        margin-bottom: 5px;
      }
      &.c-ttl--center {
        align-items: center;
        justify-content: center;
      }
    }

    //バンザイ
    &--halation {
      &>small {
        color: $accentColor;
        display: flex;
        align-items: flex-start;
        padding: 0 1.2em;
        &>span {
          position: relative;
          display: inline-block;
          &::before,&::after {
            content: '';
            width: 15px;
            height: 2px;
            display: inline-block;
            background-color: $accentColor;
            position: absolute;
            top: 50%;
          }
          &::before {
            transform: translateY(-50%) rotate(60deg);
            left: -1.2em;
          }
          &::after {
            transform: translateY(-50%) rotate(-60deg);
            right: -1.2em;
          }
        }
      }
    }

    //中央寄せ調整
    &.c-ttl {
      &--center {
        &>small {
          justify-content: center;
        }
      }
    }

    //下余白多め
    &--mbLarge {
      margin-bottom: 50px;
    }
    &--mbMiddle {
      margin-bottom: 30px;
    }
  }


  //中見出し
  &-middle {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
    color: $keyColor;
    @include mq-max(sm) {
      font-size: 22px;
    }

    //下余白多め
    &--mbLarge {
      margin-bottom: 35px;
    }

    //背景灰色
    &--bg-lightGray {
      background-color: $gray-l;
      padding: 5px 20px;
      margin-bottom: 30px;
      @include mq-max(sm) {
        padding: 5px 10px;
        margin-bottom: 20px;
      }
    }
  }


  //小見出し
  &-small {
    font-size: 21px;
    font-weight: 500;
    color: $keyColor;
    margin-bottom: 5px;
    @include mq-max(sm) {
      font-size: 19px;
    }

    //下余白多め
    &--mbLarge {
      margin-bottom: 10px;
    }
  }


  //極小見出し
  &-xsmall {
    font-size: 18px;
    font-weight: 500;
    color: $keyColor;
    margin-bottom: 5px;
    @include mq-max(sm) {
      font-size: 16px;
    }
  }

  //サブテキスト(見出しの中にsmallタグで)
  &__sub {
    font-size: 80%;
    color: $keyColor;
    display: block;
    margin-bottom: 0;
  }


  //Modifier
  //中央寄せ
  &--center {
    text-align: center;
  }

  //白文字
  &--white {
    color: $white;
  }

  //黒
  &--textColor {
    color: $textColor;
  }

  //margin-bottom0
  &--mb0 {
    margin-bottom: 0;
  }

}