//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0;
  @include mq-max(content) {
    width: $full-size;
    padding-top: $sectionPadding - 20px;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}
[class*="col-1"],
[class*="col-2"],
[class*="col-3"],
[class*="col-4"] {
  p {
    line-height: 1.7;
    @include mq-max(xs) {
      line-height: 1.8;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: $sectionPadding 0;
  @include mq-max(content) {
    padding: 0 $contentPadding+10px $sectionPadding;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding $sectionPadding;//お好みで変更
  }
  @include mq-max(xs) {
    padding: 0 $contentPadding/2 $sectionPadding;//お好みで変更
  }
  &__inner {
    padding: 0 0 $sectionInnerPadding;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
    &--contentWidth {
      max-width: $contentWidth;
      margin-right: auto;
      margin-left: auto;
      @include mq-max(content) {
        padding-left: $contentPadding+10px;
        padding-right: $contentPadding+10px;
      }
      @include mq-max(sm) {
        padding-left: $contentPadding;
        padding-right: $contentPadding;
      }
      @include mq-max(xs) {
        padding-left: $contentPadding/2;
        padding-right: $contentPadding/2;
      }
    }
    &--lowBottom {
      padding-bottom: 30px;
    }
    &--midBottom {
      padding-bottom: 40px;
    }
  }

  &__content {
    padding-bottom: 30px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  //上padding無し
  &--noTop {
    padding-top: 0;
  }

  //上下padding大き目
  &--lg {
    padding-top: 120px;
    padding-bottom: 120px;
    @include mq-max(content) {
      padding-top: 0;
      padding-bottom: $sectionPadding;
    }
  }

  //各ページ最後のl-sectionに
  &--last {
    padding-bottom: $sectionPadding * 2 + 60px;
  }

  //横幅調整用
  &--full {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  &--medium {
    max-width: 800px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--small {
    max-width: 600px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  // [class*="grid"] {
  //   margin-bottom: -1 * $contentPadding;
  // }
}


//max-width(l-sectionやl-section__innerと同じ場所では使わない。内部で使う)
.l-mw {
  margin: 0 auto;
  width: 100%;
  &--contentWidth {
    max-width: $contentWidth;
  }
  &--700 {
    max-width: 700px;
  }
  &--800 {
    max-width: 800px;
  }
  &--960 {
    max-width: 960px;
  }
}


//background共通設定
@mixin bg_scss() {
  @include mq-max(content) {
    padding-top: $sectionPadding;
    margin: 0 0 $sectionPadding;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//背景色（.l-sectionを囲って使う）
.l-bg {
  @include bg_scss();
  &--keyColor {
    background-color: $keyColor;
  }
  &--lightGray {
    background-color: $gray-l;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
$breakpoint-value : xs,sm,md,lg;

.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
  //「$breakpoint-value」で設定したブレイクポイント配列でクラスを作成。
  //例：.u-taC--xs-taL
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taL {
        @include mq-max(#{$breakpoint}) {
          text-align: left !important; //leftに切り替え
        }
      }
    }
  }
}
.u-taR {
  text-align: right !important;
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taL {
        @include mq-max(#{$breakpoint}) {
          text-align: left !important; //leftに切り替え
        }
      }
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-under-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
}

.u-not-under-content {
  @include mq-max(content) {
    display: none !important;
  }
}
.u-not-under-md {
  @include mq-max(md) {
    display: none !important;
  }
}
.u-not-under-xs {
  @include mq-max(xs) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-anchorFix {
  @include mq-max(content) {
    padding-top: $sectionPadding;
    margin-top: -1 * $sectionPadding;
  }
  //背景色あり（l-bg）と併用する場合
  &--bg {
    //今のところ追加記述必要なし
  }
  //l-section__inner用
  &__inner {
    padding-top: $sectionInnerPadding;
    margin-top: -1 * $sectionInnerPadding;
    @include mq-max(sm) {
      padding-top: $sectionInnerPadding - 10px;
      margin-top: -1 * $sectionInnerPadding + 10px;
    }
    //微調整
    &--30px {
      padding-top: $sectionInnerPadding + 30px;
      margin-top: -1 * $sectionInnerPadding - 30px;
    }
  }

  //上Paddingを0に(l-sectionと一緒に使う)
  &--noTop {
    padding-top: 80px;
    margin-top: -80px;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
$l-alternate__contentPadding: 44px;
.l-alternate {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  @include mq-max(content) {
    align-items: flex-start;
  }
  @include mq-max(iPadPro-v) {
    flex-direction: column;
    margin-bottom: 60px;
  }
  &:last-child {
    margin-bottom: 0;
  }

  //逆パターン
  &--reverse {
    flex-direction: row-reverse;
    @include mq-max(iPadPro-v) {
      flex-direction: column;
    }
  }

  //構成パーツ
  &__item {
    width: 50%;
    @include mq-max(iPadPro-v) {
      width: 100%;
    }
    &--pic {
      width: calc(50% - #{$l-alternate__contentPadding} / 2);
      @include mq-max(md) {
        width: calc(50% - #{$l-alternate__contentPadding} / 4);
      }
      @include mq-max(iPadPro-v) {
        width: 100%;
        max-width: 100%;
        margin: 0 auto 20px;
      }
      img {
        line-height: 1;
        display: block;
      }
    }
    &--content {
      width: calc(50% + #{$l-alternate__contentPadding} / 2);
      @include mq-max(md) {
        width: calc(50% + #{$l-alternate__contentPadding} / 4);
      }
      @include mq-max(iPadPro-v) {
        width: 100%;
      }
    }
  }
  &__head {
    position: relative;
    margin-bottom: 18px;
    &::after {
      content: '';
      display: inline-block;
      width: 192px;
      height: 1px;
      background-color: $accentColor;
      position: absolute;
      bottom: 0;
      left: 0;
      @include mq-max(xs) {
        width: 70px;
      }
    }
    &-number {
      &::before {
        content: counter(num, decimal-leading-zero);
        counter-increment: num;
        @include indexNumber();
        display: block;
        border-bottom: 1px solid $keyColor;
        padding-left: $l-alternate__contentPadding;
        padding-bottom: 15px;
        @include mq-max(md) {
          padding-left: $l-alternate__contentPadding/2;
        }
        @include mq-max(xs) {
          padding-left: $l-alternate__contentPadding/4;
        }
      }
    }
  }
  &__body {
    padding: 0 $l-alternate__contentPadding;
    @include mq-max(md) {
      padding: 0 $l-alternate__contentPadding/2;
    }
    @include mq-max(iPadPro-v) {
      padding: 0;
    }
  }

  //レイアウト
  // &__container {}
  &__wrap {
    counter-reset: num;
  }
}


//強み（画像無し）
.l-strengths {
  &__head {
    position: relative;
    margin-bottom: 20px;
    &::before {
      content: counter(num, decimal-leading-zero);
      counter-increment: num;
      @include indexNumber();
      display: block;
      border-bottom: 1px solid $keyColor;
      text-align: center;
      padding-bottom: 15px;
    }
    &::after {
      content: '';
      display: inline-block;
      width: 192px;
      height: 1px;
      background-color: $accentColor;
      position: absolute;
      bottom: 0;
      left: 0;
      @include mq-max(xs) {
        width: 70px;
      }
    }
  }
  &__body {
    max-width: 780px;
    margin: 0 auto;
  }
  &__title {
    text-align: center;
    @include mq-max(sm) {
      text-align: left;
    }
  }

  //レイアウト
  &__container {
    counter-reset: num;
  }
  &__item {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}