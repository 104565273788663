.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: 2;
  &:last-child {
    margin-bottom: 0;
  }

  //丸付き
  &--disc {
    & > .c-list__item {
      position: relative;
      &::before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: -0.1em;
        left: -1em;
        font-size: 1.5em;
        line-height: 1.5;
        @media all and (-ms-high-contrast: none) {
          top: -0.1em;
        }
      }
    }
  }

  //四角付き
  &--rect {
    & > .c-list__item {
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: $keyColor;
        position: absolute;
        top: .6em;
        left: -0.6em;
        font-size: 1.5em;
        line-height: 1.5;
        @media all and (-ms-high-contrast: none) {
          top: .6em;
        }
      }
    }
  }

  //チェック
  &--check {
    padding-left: 0;
    & > .c-list__item {
      position: relative;
      padding-left: 1.2em;
      &::before {
        @include fa('f14a');
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    //Modifier
    &-dash {
      & > .c-list__item {
        font-size: 18px;
        font-weight: 500;
        padding-left: 1.3em;
        background-image : linear-gradient(to right, $bdrColor, $bdrColor 5px, transparent 5px, transparent 5px);
        background-size: 8px 1px;
        background-position: left bottom;
        background-repeat: repeat-x;
        padding-top: 10px;
        padding-bottom: 10px;
        @include mq-max(xs) {
          font-size: 16px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        &::before {
          font-size: 20px;
          top: .5em;
          @include mq-max(xs) {
            top: .1em;
          }
        }
      }
    }
  }

  //大き目の数字
  &--rectNum {
    padding-left: 0;
    counter-reset: num;
    & > .c-list__item {
      font-size: 18px;
      font-weight: 500;
      background-image : linear-gradient(to right, $bdrColor, $bdrColor 5px, transparent 5px, transparent 5px);
      background-size: 8px 1px;
      background-position: left bottom;
      background-repeat: repeat-x;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 46px;
      position: relative;
      &:last-child {
        background: none;
      }
      @include mq-max(xs) {
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 35px;
      }
      &::before {
        counter-increment: num;
        content: counter(num);
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        position: absolute;
        top: .5em;
        left: 0;
        background-color: $keyColor;
        @include mq-max(xs) {
          font-size: 18px;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  //カウント
  &--count {
    counter-reset: count;
    & > .c-list__item {
      position: relative;
      &::before{
        counter-increment: count;
        content:counter(count);
        position: absolute;
        left: -1.1em;
        color: $keyColor;
      }
    }
    &--brackets{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          position: absolute;
          left: -1.5em;
          color: $keyColor;
        }
      }
    }
  }

  //注釈
  &--comment {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &::before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: -1.5em;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
    }
    //番号付き注釈
    &-num {
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        padding-left: 0.5em;
        &::before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          left: -1.5em;
          @media all and (-ms-high-contrast: none) {
            top: 0;
          }
        }
      }
    }
  }
  //英語サイト用注釈
  &--asterisk {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 30px;
    padding-left: 0.3em;
    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &::before{
        content: '*';
        display: inline-block;
        position: absolute;
        left: -0.5em;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
    }
  }
}


.c-infoList {
  &__item {
    border-bottom: 1px solid $bdrColor;
    &:first-child {
      border-top: 1px solid $bdrColor;
    }
  }
  &__inner {
    padding: 16px 250px 16px 20px;
    position: relative;
    @include mq-max(iPadPro-v) {
      padding: 16px 20px;
    }
    @include mq-max(xs) {
      padding: 16px 10px;
    }
  }
  &__more {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include mq-max(iPadPro-v) {
      position: relative;
      top: auto;
      right: auto;
      transform: none;
      margin-top: 20px;
    }
  }
  // &__body {}
  &__content {
    margin-right: 14px;
    margin-bottom: 30px;
    @include mq-max(md) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
      @include mq-max(md) {
        margin-bottom: 0;
      }
    }
    &-item {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }

      //住所
      &--address {
        display: flex;
        @include mq-max(md) {
          flex-direction: column;
          margin-bottom: 10px;
        }
        .zipcode {
          margin-right: .5em;
          @include mq-max(sm) {
            display: block;
            margin: 0;
          }
        }
        .address {
          margin-right: .5em;
          @include mq-max(md) {
            margin-right: 0;
          }
          @include mq-max(sm) {
            display: block;
            margin: 0;
          }
          //英語調整
          .eng & {
            margin-right: 0;
          }
        }

        //英語調整
        .eng & {
          flex-direction: column;
        }
      }
    }
  }

  //タイトルのみ
  &--simple {
    .c-infoList {
      &__inner {
        padding: 38px 250px 38px 20px;
        @include mq-max(xs) {
          padding: 16px 10px;
        }
      }
      &__more {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: 0;
        @include mq-max(xs) {
          position: relative;
          top: auto;
          right: auto;
          transform: none;
          margin-top: 20px;
        }
      }
    }

    //パーツ調整
    .c-ttl-middle {
      margin-bottom: 0;
      @include mq-max(xs) {
        margin-bottom: 10px;
      }
    }
  }

}


//テーブルリスト
.c-tblList {
  // &__item {}
  &__wrap {
    display: flex;
  }
  &__head {
    flex: 0 0 70px;
    &--longer {
      flex-basis: 120px;
    }
    &--colon {
      position: relative;
      padding-right: 10px;
      &::after {
        content: ':';
        display: inline-block;
        position: absolute;
        top: 0;
        right: 2px;
      }
    }
  }
  &__desc {
    flex-grow: 1;
    flex-shrink: 1;
  }
}