//定義リスト

.c-def {
  &__item {
    padding: 22px 0;
    border-top: 1px solid $bdrColor;
    @include mq-max(sm) {
      padding: 20px $contentPadding/2;
    }
    &:last-child {
      border-bottom: 1px solid $bdrColor;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: $keyColor;
    padding-left: 30px;
    position: relative;
    @include mq-max(sm) {
      font-size: 18px;
    }
    &::before {
      content: '';
      @include blue-red_block;
      position: absolute;
      top: .5em;
      left: 0;
    }
  }
  &__desc {
    font-size: 16px;
    line-height: (28 / 16);
    @include mq-max(xs) {
      font-size: 15px;
    }
  }
}