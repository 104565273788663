//吹き出し
.c-fukidashi {
  background-color: $white;
  padding: 55px 80px 66px;
  display: inline-flex;
  justify-content: center;
  @include mq-max(content) {
    max-width: 100%;
  }
  @include mq-max(md) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include mq-max(xs) {
    padding: 40px 15px 50px;
  }
  filter: drop-shadow($box-shadow);
  .is-IE & {
    box-shadow: $box-shadow;
  }
  position: relative;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 32.5px 0 32.5px;
    border-color: $white transparent transparent transparent;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__inner {
    margin: 0 auto;
    text-align: left;
  }
}
